








































import { IInvitedUsers } from '@/api/schema';
import { PropType } from 'vue';
import { allUserRoles } from '@/helpers/inviteUserHelpers';
import { DateTime } from 'luxon';

interface IInvitedUsersList extends IInvitedUsers {
  resend: boolean;
}
export default {
  name: 'InviteUserList',
  props: {
    userList: {
      type: Array as PropType<IInvitedUsers[]>,
      default: (): any => []
    }
  },
  computed: {
    users(): IInvitedUsersList[] {
      return this.userList.map((user: IInvitedUsers) => ({
        ...user,
        role: allUserRoles.find((item) => item.value === user.role).label,
        resend: this.checkResendDate(user.expired)
      }));
    }
  },

  methods: {
    checkResendDate(expiredDate: string): boolean {
      const date = DateTime.fromFormat(expiredDate, 'yyyy-LL-dd HH:mm:ss', {
        zone: 'UTC'
      });
      return date <= DateTime.utc();
    }
  }
};
